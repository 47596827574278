import { FC, useEffect, useRef } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { dispatch, useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import { AppState } from './store/Store';
import { Toaster } from 'react-hot-toast';

import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import i18n from './utils/i18n';

import { useLazyMeQuery } from './services/auth';
import { setUser } from './store/apps/auth/AuthSlice';

import { AlertDialogProvider } from './hooks/useAlertDialog';
import { useLazyFetchPitchesQuery, useLazyFetchStadiumsQuery } from './services/stadiums';
import {
  setAllPitches,
  setFemaleFriendly,
  setPitch,
  setPitches,
  setRefreshPitches,
  setSelectedStadium,
  setStadiums,
} from './store/apps/config/ConfigSlice';
import { Price } from './types/apps/stadium';

import './Global.css';
import { PusherProvider } from './contexts/PusherContext';
import { useLazyGetWalletBalanceQuery } from './services/wallet';
import { setWallet } from './store/apps/wallet/WalletSlice';
import { useStatisticsMutation } from './services/reporting';
import { setSelectedPitchId, setStatistics } from './store/apps/reporting/ReportingSlice';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  const [fetchUser] = useLazyMeQuery();
  const pitchIds = useRef<number[]>([]);

  const [fetchStadiums] = useLazyFetchStadiumsQuery();
  const [fetchPitches] = useLazyFetchPitchesQuery();
  const [fetchWallet] = useLazyGetWalletBalanceQuery();
  const [statisticsRequest] = useStatisticsMutation();

  const customizer = useSelector((state: AppState) => state.customizer);
  const user = useSelector((state: AppState) => state.authReducer.user);
  const selectedStadium = useSelector((state: AppState) => state.configReducer.selectedStadium);
  const dateTypeFilter = useSelector((state) => state.reportingReducer.dateTypeFilter);
  const pitches = useSelector((state) => state.configReducer.pitches);
  const selectedPitchId = useSelector((state) => state.reportingReducer.selectedPitchId);
  const refreshPitches = useSelector((state) => state.configReducer.refreshPitches);

  useEffect(() => {
    if (user) {
      fetchUser('').then((result) => {
        dispatch(
          setUser({
            data: {
              token: user.token,
              data: result?.data?.data,
            },
          }),
        );
      });
    }

    if (customizer.activeDir === 'ltr') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ar');
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchStadiums(undefined, undefined).then((result) => {
        const stadiums = result?.data?.data;
        try {
          dispatch(setStadiums(stadiums));

          if (!selectedStadium && Array.isArray(stadiums) && stadiums?.length > 0) {
            dispatch(setSelectedStadium(stadiums?.[0]));
          }
        } catch (e) {}
      });
      fetchWallet().then((result) => {
        dispatch(setWallet(result.data?.data));
      });
    }
  }, [user]);

  useEffect(() => {
    if (selectedStadium?.id && user) {
      fetchPitches({
        stadium_id: selectedStadium?.id,
      }).then((result) => {
        const data = result.data?.data;
        const visiblePitches = data?.filter((item) => item.is_visible);

        dispatch(setPitches(visiblePitches));
        dispatch(setAllPitches(data));
        dispatch(setRefreshPitches(false));

        if (visiblePitches && visiblePitches?.length > 0) {
          dispatch(setPitch(visiblePitches?.[0]));
          const femaleFriendly: Price[] = visiblePitches[0]?.prices?.filter(
            (item) => item.female_friendly,
          );
          dispatch(setFemaleFriendly(femaleFriendly));
        }
      });
    }
  }, [selectedStadium, refreshPitches]);

  useEffect(() => {
    if (selectedStadium?.id && user && pitches?.length > 0) {
      pitchIds.current = pitches?.map((pitch) => pitch?.id) || [];
    }
  }, [pitches]);

  useEffect(() => {
    if (selectedStadium?.id && user && pitches?.length > 0) {
      dispatch(setSelectedPitchId(pitchIds.current));
    }
  }, [pitchIds.current, pitches]);

  useEffect(() => {
    if (selectedPitchId?.length > 0 && pitches?.length > 0) {
      statisticsRequest({
        date: dateTypeFilter,
        pitches: selectedPitchId,
      }).then((res: any) => {
        dispatch(setStatistics(res?.data?.data));
      });
    }
  }, [dateTypeFilter, selectedPitchId]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir || 'l'}>
        <PusherProvider>
          <AlertDialogProvider>
            <CssBaseline />
            <ScrollToTop>{routing}</ScrollToTop>
            <Toaster />
          </AlertDialogProvider>
        </PusherProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
